import {
    Component,
    OnInit,
    ChangeDetectorRef,
    AfterContentChecked,
    ElementRef,
    QueryList,
    ViewChildren,
    ViewChild
} from '@angular/core';
import {
    FlowObjectType,
    FlowObjectInstance,
    FlowObjectInstanceState,
    FlowObjectInstanceStateDescription,
    FlowObjectTypeCategory
} from '../../models/flow-object.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowInstance } from '../../models/flow.model';
import { BaseComponent } from '../../components/base/base.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../shared/enums';
import { FlowInstanceService } from '../../services/flow-instance.service';
import { AuthService } from '../../services/auth.service';
import { FlowObjectInstanceService } from '../../services/flow-object-instance.service';
import { Utils } from '../../shared/utils';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { FlowObjectDefinitionService } from '../../services/flow-object-definition.service';

@Component({
    selector: 'flow-instance',
    templateUrl: './flow-instance.component.html',
    styleUrls: ['./flow-instance.component.scss']
})
export class FlowInstanceComponent extends BaseComponent implements OnInit, AfterContentChecked {
    // #region [ViewChild]
    @ViewChildren('wrapperRef') wrapperRef: QueryList<ElementRef>;
    @ViewChildren('taskHeaderSeparatorRef') taskHeaderSeparatorRef: QueryList<ElementRef>;
    @ViewChildren('taskContainerRef') taskContainerRef: QueryList<ElementRef>;
    @ViewChildren('secondGroupRef') secondGroupRef: QueryList<ElementRef>;
    @ViewChild('descriptionMessageRef') descriptionMessageRef: ElementRef;
    @ViewChild('descriptionAreaRef') descriptionAreaRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    FlowObjectInstanceStateDescription: typeof FlowObjectInstanceStateDescription = FlowObjectInstanceStateDescription;
    FlowObjectTypeCategory: typeof FlowObjectTypeCategory = FlowObjectTypeCategory;
    Enums = Enums;
    // #endregion

    // #region [properties]
    model: FlowInstance;
    lastFinishedTaskOuterIndex: number;
    intervalControl: any = null;
    isBootstrapFinished: boolean = false;
    isShowingDescription: boolean = false;
    isFromConecta: boolean = false;
    conectaEnvironmentSegment: string = '';
    drawableFlowObjectInstances: FlowObjectInstance[] = [];
    startInboundApiTimer: string = '??:??';
    unitAcronym: string = null;
    organizationAcronym: string = null;
    patriarchAcronym: string = null;
    // #endregion

    // #region [getters]
    get showStartInboundApiTaskTimer(): boolean {
        let flowObjectDefinition = this.model?.flowDefinition?.flowObjectDefinitions?.find(x => x.typeId == FlowObjectType.StartInboundApi);
        if (flowObjectDefinition == null) {
            return false;
        }

        let flowObjectInstance = this.model?.flowObjectInstances?.find(x => x.flowObjectDefinitionId == flowObjectDefinition.id);

        return flowObjectInstance?.stateId == FlowObjectInstanceState.Started;
    }
    // #endregion

    constructor(
        route: ActivatedRoute,
        router: Router,
        spinner: NgxSpinnerService,
        toastr: ToastrService,
        public sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        private confirmationService: ConfirmationService,
        private cookieService: CookieService,
        private titleService: Title,
        private authService: AuthService,
        private flowInstanceService: FlowInstanceService,
        private flowObjectInstanceService: FlowObjectInstanceService,
        private flowObjectDefinitionService: FlowObjectDefinitionService
    ) {
        super(route, router, spinner, toastr);

        if (atob(this.cookieService.get('prodest-eflow-cnc')).toLowerCase() == 'true') {
            this.isFromConecta = this.route.snapshot.url.some(x => x.path == Enums.PagesPaths.CatalogService.slice(1));
        }

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        switch (atob(this.cookieService.get('prodest-eflow-env')).toLowerCase()) {
            case 'des':
                this.conectaEnvironmentSegment = 'dev.';
                break;

            case 'hom':
                this.conectaEnvironmentSegment = 'hom.';
                break;

            case 'tre':
                this.conectaEnvironmentSegment = 'hom.';
                break;
        }

        try {
            const response = await this.flowInstanceService.getById(this.paramId, isNaN(this.paramVersion) ? null : this.paramVersion);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                this.router.navigate([Enums.PagesPaths.FlowInstanceList]);
                return;
            }

            this.model = response.data;
            this.model.flowObjectInstances.sort((a, b) => a.flowObjectDefinition.outerIndex - b.flowObjectDefinition.outerIndex);
            this.drawableFlowObjectInstances = this.model.flowObjectInstances.filter(x => {
                return x.flowObjectDefinition.typeId != FlowObjectType.GatewayPaths
                    && [
                        FlowObjectInstanceState.Started,
                        FlowObjectInstanceState.AwaitingAction,
                        FlowObjectInstanceState.Finished,
                        FlowObjectInstanceState.ManuallyFinished,
                        FlowObjectInstanceState.AutomaticallyCancelled,
                        FlowObjectInstanceState.ManuallyCancelled
                    ].includes(x.stateId);
            });

            if (this.drawableFlowObjectInstances.length == 1) {
                this.toggleDescription();
            }

            let startInboundApiTask = this.drawableFlowObjectInstances.find(x => x.flowObjectDefinition.typeId == FlowObjectType.StartInboundApi);
            if (startInboundApiTask != null) {
                const response = await this.flowObjectDefinitionService.getFormData(startInboundApiTask.flowObjectDefinitionId);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                startInboundApiTask.flowObjectDefinition.formSchema = response.data;
            }

            // Encaminhamento no E-Docs não pode ser realizado por sistema, apenas usuário;
            // com isso, não deve haver FlowObjectInstance que tenha hasUserInteraction == false e esteja Started
            //if (this.model.flowObjectInstances.find(x => x.stateId == FlowObjectInstanceState.Started && !x.hasUserInteraction) != null) {
            //    if (this.intervalControl == null) {
            //        this.intervalControl = setInterval(async () => {
            //            this.spinner.show();
            //            await this.ngOnInit();
            //        }, 4000);
            //    }
            //} else if (this.intervalControl != null) {
            //    clearInterval(this.intervalControl);
            //}

            this.unitAcronym = this.model.unitInfo.split(' - ')[0].trim();
            this.organizationAcronym = this.model.organizationInfo.split(' - ')[0].trim();
            this.patriarchAcronym = this.model.patriarchInfo.split(' - ')[0].trim();

            this.titleService.setTitle(`${this.titleService.getTitle().split('|')[0].trim()} | ${this.model.name} (${this.organizationAcronym}-${this.unitAcronym})`);

            this.setStartInboundApiTimer();
        } catch (error) {
            this.toastr.error(Enums.Messages.GeneralError, Enums.Messages.Error, Utils.getToastrErrorOptions());
            console.error(error);
            this.router.navigate([Enums.PagesPaths.FlowInstanceList]);
            this.spinner.hide();
        } finally {
            setTimeout(() => {
                this.isBootstrapFinished = true;
                setTimeout(() => {
                    let wrappers = this.wrapperRef.toArray();
                    if (wrappers.length > 0) {
                        wrappers.forEach(item => item.nativeElement.style.height = '100%');
                    }
                    this.spinner.hide();
                }, 500);
            }, 300);
        }
    }

    ngAfterContentChecked() {
        this.changeDetectorRef.detectChanges();
    }

    // ======================
    // public methods
    // ======================

    shouldKeepTaskBodyClosed(idx: number, flowObjectInstance: FlowObjectInstance): boolean {
        return idx < this.drawableFlowObjectInstances.length - 1
            && ![
                FlowObjectInstanceState.Started,
                FlowObjectInstanceState.AutomaticallyCancelled,
                FlowObjectInstanceState.ManuallyCancelled,
            ].includes(flowObjectInstance.stateId);
    }

    isFlowInstanceRelatedToUser(): boolean {
        return this.model.flowObjectInstanceActors.some(x => x.actorId == this.authService.user.id);
    }

    getDateString(flowObjectInstance: FlowObjectInstance): string {
        switch (flowObjectInstance.stateId) {
            case FlowObjectInstanceState.Started:
                return flowObjectInstance.startDate != null ? new Date(flowObjectInstance.startDate).toLocaleString() : '';

            case FlowObjectInstanceState.Finished:
            case FlowObjectInstanceState.ManuallyFinished:
            case FlowObjectInstanceState.AutomaticallyCancelled:
            case FlowObjectInstanceState.ManuallyCancelled:
                return flowObjectInstance.finishDate != null ? new Date(flowObjectInstance.finishDate).toLocaleString() : '';

            default:
                return new Date(flowObjectInstance.updateDate).toLocaleString();
        }
    }

    // controla as ações de exibição/recolhimento dos detalhes de cada Tarefa
    toggleTaskBody(event) {
        let toggleBodyElement = event.currentTarget.parentElement.querySelector('.toggle-body');
        toggleBodyElement.classList.contains('on') ? toggleBodyElement.classList.remove('on') : toggleBodyElement.classList.add('on');

        let taskHeaderSeparator = this.taskHeaderSeparatorRef.toArray().find(x =>
            x.nativeElement.parentElement == event.composedPath().find(y => y.classList.contains('task-area'))
        );
        let taskContainer = this.taskContainerRef.toArray().find(x =>
            x.nativeElement.parentElement == event.composedPath().find(y => y.classList.contains('task-area'))
        );

        if (taskHeaderSeparator.nativeElement.classList.contains('d-none')) {
            taskHeaderSeparator.nativeElement.classList.remove('d-none');
            taskContainer.nativeElement.classList.remove('d-none');
            setTimeout(() => taskContainer.nativeElement.classList.remove('hidden'), 1);
        } else {
            taskContainer.nativeElement.classList.add('hidden');
            setTimeout(() => taskHeaderSeparator.nativeElement.classList.add('d-none'), 100);
            setTimeout(() => taskContainer.nativeElement.classList.add('d-none'), 300);
        }
    }

    // controla as ações de exibição/recolhimento das informações do header de cada Tarefa
    toggleHeaderInfo(event) {
        event.currentTarget.classList.toggle('info-on');

        let secondGroup = this.secondGroupRef.toArray().find(x =>
            x.nativeElement.parentElement == event.composedPath().find(y => y.classList.contains('task-header'))
        );

        if (secondGroup.nativeElement.classList.contains('display-none')) {
            secondGroup.nativeElement.classList.remove('display-none');
            setTimeout(() => secondGroup.nativeElement.classList.remove('hidden'), 1);
        } else {
            secondGroup.nativeElement.classList.add('hidden');
            setTimeout(() => secondGroup.nativeElement.classList.add('display-none'), 100);
        }
    }

    async executeTask(flowObjectInstance: FlowObjectInstance) {
        this.confirmationService.confirm({
            message: Enums.Messages.ConfirmExecuteTask,
            accept: async () => {
                this.spinner.show();

                const response = await this.flowObjectInstanceService.executeTask(flowObjectInstance);

                this.spinner.hide();

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    this.reload();
                    return;
                }

                this.toastr.success(response.message.description, Enums.Messages.Success);
                this.reload();
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    async cancelFlowInstance(event: FlowObjectInstance) {
        this.confirmationService.confirm({
            message: Enums.Messages.ConfirmCancelTask,
            accept: async () => {
                this.spinner.show();

                const response = await this.flowObjectInstanceService.cancelFlowInstanceRegisterProcess(event);

                this.spinner.hide();

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.toastr.success(response.message.description, Enums.Messages.Success);
                this.reload();
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    outboundApiChangeHandler(flowObjectInstance: FlowObjectInstance) {
        let match = this.drawableFlowObjectInstances.find(x => x.id == flowObjectInstance.id);
        Object.assign(match, flowObjectInstance);
    }

    getDescriptionHtml(): string {
        return this.model?.flowDefinition?.publishedDescription;
    }

    toggleDescription() {
        let interval = setInterval(() => {
            if (this.descriptionAreaRef == null) return;

            clearInterval(interval);

            this.isShowingDescription = !this.isShowingDescription;

            setTimeout(() => this.descriptionAreaRef.nativeElement.style.height = !this.isShowingDescription
                ? '0'
                : `calc(${this.descriptionMessageRef.nativeElement.clientHeight}px + 50px)`
            , 1);
        }, 300);
    }

    async setStartInboundApiTimer() {
        const response = await this.flowInstanceService.getServerTime();

        let serverNow = new Date(response).getTime();
        let localNow = new Date().getTime();
        let delta = localNow - serverNow;

        let interval = setInterval(() => {
            if (this.model == null) return;
            if (!this.showStartInboundApiTaskTimer || this.startInboundApiTimer == '00:00') {
                clearInterval(interval);
                return;
            }

            let start = new Date(this.model.createDate).getTime();
            let now = new Date().getTime() - delta;
            let elapsedTime = Math.round((now - start) / 1000);
            let remainingTime = (5 * 60) - elapsedTime;
            remainingTime = remainingTime < 0 ? 0 : remainingTime;
            let seconds = remainingTime % 60;
            let minutes = Math.floor(remainingTime / 60);

            this.startInboundApiTimer = `${minutes.toFixed(0).padStart(2, '0')}:${(seconds == 60 ? 0 : seconds).toFixed(0).padStart(2, '0')}`;
        }, 1000);
    }

    // ======================
    // private methods
    // ======================

    private reload() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([Enums.PagesPaths.FlowInstance, this.paramId]));
    }
}
